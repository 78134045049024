import { Channel } from "../../../store/job/channel";
import { Face, Part, SyntheticMeshInfo, TreeviewFaceGroup } from "../../../store/job/job-data";
import { Edge, MeshDataCopyElementIterable, drawFace, makeFaceKey, makePointKey } from "../../../utils/hoops.utils";
import { SelectOperator } from "./SelectOperator";

type GetChannelBodiesFn = (face: Face) => (Part | TreeviewFaceGroup)[];

type GetMeshFn = (face: Face) => SyntheticMeshInfo | null;

type MeshDataCache = Map<number, {
    meshData: Communicator.MeshDataCopy,
    translationMatrix: Communicator.Matrix
}>;

export type ChannelEndMesh = { nodeId: number, faceIndex: number, config: SyntheticMeshInfo };

export class SelectTerminalOperator extends SelectOperator {
    onStart?: () => void;

    onComplete?: (face: Face | ChannelEndMesh, shiftModifier: boolean) => void;

    onError?: (msg: string) => void;

    getChannelBodies: GetChannelBodiesFn = () => [];

    getMesh: GetMeshFn = () => null;

    _openEdges: Edge[] = [];

    _faceOpenEdgeMap = new Map<string, Edge[]>;

    public async onMouseDown(event: Communicator.Event.MouseInputEvent) {
        this._selectedItem = await this._hwv.view.pickFromPoint(event.getPosition(), new Communicator.PickConfig(Communicator.SelectionMask.Face));
    }

    public async onMouseMove(_: Communicator.Event.MouseInputEvent) {
        delete this._selectedItem;
    }

    public async onMouseUp(event: Communicator.Event.MouseInputEvent): Promise<void> {
        if (this._selectedItem) {
            const nodeId = this._selectedItem.getNodeId();
            const face = this._selectedItem.getFaceEntity();
            const faceIndex = face?.getCadFaceIndex();
            const refPoint = this._selectedItem.getPosition();

            if (refPoint && nodeId && faceIndex !== undefined) {
                const selectionMode = this._detectSelectionMode({ nodeId, faceIndex });

                if (this._highlight) {
                    this._hwv.model.setNodeFaceHighlighted(nodeId, faceIndex, true);
                }

                this.onStart?.();

                const shiftModifier = event.getModifiers() == Communicator.KeyModifiers.Shift;

                if (selectionMode === 'edge') {
                    let meshInfo = this.getMesh?.({ nodeId, faceIndex });

                    if (meshInfo) {
                        this.onComplete?.({ nodeId, faceIndex, config: meshInfo }, shiftModifier);
                    } else if (refPoint) {
                        const vertices = await this._getTerminalVertices(refPoint);

                        if (vertices.length > 2) {
                            const meshInfo = drawFace(vertices);

                            meshInfo && this.onComplete?.({ nodeId, faceIndex, config: meshInfo }, shiftModifier);
                        } else {
                            this.onError?.('Could not detect terminal surface');
                        }
                    }
                } else {
                    this.onComplete?.({ nodeId, faceIndex }, shiftModifier);
                }
            }
        }

        event.setHandled(true);
    }

    public async buildEdgeMap(channels: Channel[]): Promise<void> {
        const channelBodyFaces = channels.flatMap(c => c.getBodyFaceGroups()).flatMap(fg => fg.config).filter(f => Channel.isSyntheticFace(f) === false);
        const nodeIds = new Set<number>([...channelBodyFaces.map(f => f.nodeId)]);

        const meshDataCache = new Map<number, {
            meshData: Communicator.MeshDataCopy,
            translationMatrix: Communicator.Matrix
        }>();

        for (const nodeId of nodeIds) {
            const meshData = await this._hwv.model.getNodeMeshData(nodeId);
            const translationMatrix = this._hwv.model.getNodeNetMatrix(nodeId);

            meshDataCache.set(nodeId, {
                meshData,
                translationMatrix
            });
        }

        const faceVerticesMap = this._buildFaceVerticesMap(
            channelBodyFaces,
            channels.flatMap(c => c.baffles).filter(Channel.isTreeviewFaceGroup).flatMap(fg => fg.config).filter(f => Channel.isSyntheticFace(f) === false),
            meshDataCache
        )

        const channelBodyEdges = await this._findChannelBodyEdges(meshDataCache, faceVerticesMap);

        this._openEdges = this._findOpenEdges(channelBodyEdges, faceVerticesMap);

        this._faceOpenEdgeMap = this._buildEdgeFaceMap(this._openEdges, faceVerticesMap);
    }

    private async _getTerminalVertices(refPoint: Communicator.Point3): Promise<Communicator.Point3[]> {
        const sortedEdges = this._openEdges.sort((e1, e2) => e1.minDistance(refPoint) - e2.minDistance(refPoint));
        const getConnectedEdges = (currentEdge: Edge, startEdge: Edge, processedEdges: Edge[]) => {
            const processedEdgesKeys = processedEdges.map(e => e.key);

            return sortedEdges
                .filter(e => processedEdgesKeys.includes(e.key) === false)
                .filter(e => Edge.areConnected(currentEdge, e))
                .filter(e => Edge.areOnSamePlane(startEdge, e))
        }

        let startEdge = sortedEdges[0];
        let terminalEdges: Edge[] = [startEdge];
        let connectedEdges = getConnectedEdges(startEdge, startEdge, [startEdge]);

        while (connectedEdges.length) {
            const currentEdge = connectedEdges.pop()!;
            const nextConnectedEdges = getConnectedEdges(currentEdge, startEdge, [...terminalEdges, ...connectedEdges, currentEdge]);

            connectedEdges = [...connectedEdges, ...nextConnectedEdges];
            terminalEdges.push(currentEdge);
        }

        return terminalEdges.flatMap(e => e.vertices);
    }

    private async _findChannelBodyEdges(meshDataCache: MeshDataCache, faceVerticesMap: Map<string, Set<string>>): Promise<Edge[]> {
        const channelBodyEdges: Edge[] = [];

        for (const [nodeId, { meshData: md, translationMatrix }] of meshDataCache.entries()) {
            for (let edgeIndex = 0; edgeIndex < md.lines.elementCount; edgeIndex++) {
                const vertices = [...md.lines.element(edgeIndex) as MeshDataCopyElementIterable]
                    .map(line => Communicator.Point3.createFromArray(line.position))
                    .map(v => translationMatrix.transform(v));

                const edgeBelongsToChannel = vertices.length > 0 && vertices.every(v => faceVerticesMap.has(makePointKey(v)));

                if (edgeBelongsToChannel) {
                    const edge = new Edge(nodeId, edgeIndex, vertices);
                    const props = await this._hwv.model.getEdgeProperty(nodeId, edgeIndex);

                    edge.setProps(props);
                    channelBodyEdges.push(edge);
                }

            }
        }

        return channelBodyEdges;
    }

    private _findOpenEdges(channelBodyEdges: Edge[], faceVerticesMap: Map<string, Set<string>>): Edge[] {
        const isEdgeVertex = (v: Communicator.Point3) => faceVerticesMap.get(makePointKey(v))!.size === 1;

        return channelBodyEdges.filter(e => {
            if (e.isLine() === false) {
                return e.vertices.length > 2 && e.vertices.slice(1, e.vertices.length - 1).every(isEdgeVertex);
            } else {
                const intermediateVerticesNotShared = e.vertices.length > 2 ? e.vertices.slice(1, e.vertices.length - 1).every(isEdgeVertex) : true;

                if (intermediateVerticesNotShared) {
                    const startVertex = e.vertices[0];
                    const endVertex = e.vertices[e.vertices.length - 1];
                    const edgeConnectedToStartVertex = channelBodyEdges.filter(be => be.key !== e.key).find(be => be.hasVertex(startVertex));
                    const edgeConnectedToEndVertex = channelBodyEdges.filter(be => be.key !== e.key).find(be => be.hasVertex(endVertex));

                    if (edgeConnectedToStartVertex && edgeConnectedToEndVertex) {
                        return Edge.areOnSamePlane(e, edgeConnectedToStartVertex)
                            && Edge.areOnSamePlane(e, edgeConnectedToEndVertex)
                            && Edge.areOnSamePlane(edgeConnectedToEndVertex, edgeConnectedToStartVertex);
                    } else {
                        return false;
                    }
                }

                return false;
            }
        });
    }

    private _buildFaceVerticesMap(channelBodyFaces: Face[], channelBaffleFaces: Face[], meshDataCache: MeshDataCache): Map<string, Set<string>> {
        const channelBaffleFaceKeys = channelBaffleFaces.map(makeFaceKey);
        const faceVerticesMap = new Map<string, Set<string>>();

        for (const { nodeId, faceIndex } of channelBodyFaces) {
            const md = meshDataCache.get(nodeId)!.meshData;
            const translationMatrix = meshDataCache.get(nodeId)!.translationMatrix;
            const faceKey = makeFaceKey({ nodeId, faceIndex });

            if (channelBaffleFaceKeys.includes(faceKey)) {
                continue;
            }

            [...md.faces.element(faceIndex) as MeshDataCopyElementIterable].forEach(v => {
                const point = Communicator.Point3.createFromArray(v.position);
                const pointKey = makePointKey(translationMatrix.transform(point));

                if (!faceVerticesMap.has(pointKey)) {
                    faceVerticesMap.set(pointKey, new Set([faceKey]))
                } else {
                    faceVerticesMap.get(pointKey)?.add(faceKey);
                }
            })
        }

        return faceVerticesMap;
    }

    private _buildEdgeFaceMap(openEdges: Edge[], faceVerticesMap: Map<string, Set<string>>) {
        const faceOpenEdgeMap = new Map<string, Edge[]>();

        openEdges.forEach(e => {
            const faceKeys = new Set(
                e.vertices.flatMap(v => [...faceVerticesMap.get(makePointKey(v))!.values()])
            );

            for (const faceKey of faceKeys.values()) {
                faceOpenEdgeMap.set(faceKey, [...faceOpenEdgeMap.get(faceKey) ?? [], e])
            }
        });

        return faceOpenEdgeMap;
    }

    private _detectSelectionMode(face: Face): 'face' | 'edge' {
        const faceKey = makeFaceKey(face);

        return this._faceOpenEdgeMap.has(faceKey) ? 'edge' : 'face';
    }
}