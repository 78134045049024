import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import { useContext } from "react";
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import JobContext from "../../store/job/job-context";
import theme from "../../styles/main-theme";
import { Channel } from "../../store/job/channel";
import { useTranslation } from 'react-i18next';
import { getNodeIdsForPart } from "../job/helpers/tree-mapper";

export interface ChannelBodiesProps {
    channel: Channel,
    Hwv: Communicator.WebViewer,
    onBodyDeleted: (channel: Channel, bodyId: string) => void,
    onEnterEditMode: (channel: Channel, bodyId: string, source: 'baffles' | 'bodies') => Promise<void>,
    disableControl: boolean,
    disableHighlighting: boolean
}

export function ChannelBodies(props: ChannelBodiesProps) {
    const jobContext = useContext(JobContext);
    const { t } = useTranslation();
    
    const addSelectionToChannelBody = (channelId: string, bodyId: string) => {
        const facesToAdd = jobContext.Categories.Selected;
        const config = props.channel.getBodyFaceGroups().find(b => b.id === bodyId)?.config ?? [];

        for (const newFace of facesToAdd) {
            if (!config.find(f => f.nodeId === newFace.nodeId && f.faceIndex === newFace.faceIndex)) {
                config.push(newFace);
            }
        }

        jobContext.editChannelBody(channelId, bodyId, config);
        props.Hwv.selectionManager.clear(true);
    }

    const removeBodyFromChannel = (channelId: string, partId: string) => {
        props.onBodyDeleted(props.channel, partId);
    }

    const highlightBody = (channelId: string, bodyId: string, shouldHighlight: boolean) => {
        if (props.disableHighlighting) {
            return;
        }
        
        const channel = jobContext.Categories.Channel.find(c => c.id === channelId);
        if (!channel) {
            return;
        }

        const body = channel.bodies.find(b => b.id === bodyId);

        if (body) {
            props.Hwv.model.resetModelHighlight();

            if (Channel.isTreeviewFaceGroup(body)) {
                body.config.forEach(c => props.Hwv.model.setNodeFaceHighlighted(c.nodeId, c.faceIndex, shouldHighlight));
            } else {
                props.Hwv.model.setNodesHighlighted(getNodeIdsForPart(body), shouldHighlight);
            }
        }
    }

    return (<List sx={{ padding: "0", width: "100%" }}>
        {props.channel.bodies.map(body => (
            <ListItem id={"channel-body-list-item-" + body.id} key={body.id} sx={{ padding: "0 0 0 15px", '& .MuiListItemSecondaryAction-root': { right: 0 } }} secondaryAction={
                <>
                    {Channel.isTreeviewFaceGroup(body) && <IconButton disabled={props.disableControl || props.disableHighlighting || jobContext.Categories.Selected.length === 0}
                        onClick={() => addSelectionToChannelBody(props.channel.id, body.id)}
                        sx={{ padding: 0, color: theme.palette.common.black }}>
                        <AddIcon titleAccess={t("Add face")} />
                    </IconButton>}
                    {Channel.isTreeviewFaceGroup(body) && <IconButton disabled={props.disableControl || props.disableHighlighting}
                        sx={{ padding: 0, color: theme.palette.common.black }}
                        onClick={() => props.onEnterEditMode(props.channel, body.id, 'bodies')}>
                        <RemoveIcon titleAccess={t("Remove face")} />
                    </IconButton>}
                    <IconButton disabled={props.disableControl || props.disableHighlighting} onClick={() => removeBodyFromChannel(props.channel.id, body.id)} sx={{ padding: 0, color: theme.palette.common.black }}>
                        <RemoveCircleTwoToneIcon id={"remove-channel-body-list-item-" + body.id}
                            sx={{ cursor: 'pointer' }} titleAccess={t("Remove body")} />
                    </IconButton>
                </>

            }>
                <ListItemText
                    onMouseEnter={() => highlightBody(props.channel.id, body.id, true)} onMouseLeave={() => highlightBody(props.channel.id, body.id, false)}
                    primary={body.name}
                    primaryTypographyProps={{
                        variant: 'caption',
                    }}
                />
            </ListItem>))}
    </List>)

}