import { useReducer, useState } from "react";
import clone from "../../utils/clone";
import { makeFaceKey } from "../../utils/hoops.utils";
import { Channel } from "./channel";
import { Part, SyntheticFace, SyntheticMeshInfo, TreeviewFace, TreeviewFaceGroup } from "./job-data";

export enum VisibilityStateActionType {
    UPDATE_STATE,
    UPDATE_SYNTHETIC_MESHES,
    ISOLATE_PART,
    ISOLATE_CHANNEL,
    SET_PART_VISIBILITY,
    SET_CHANNEL_VISIBILITY,
    SHOW_ALL,
    RESTORE_STATE,
    REINITIALISE
}

export enum VisibilityMode {
    REGULAR,
    ISOLATED
}

export type VisibilityState = {
    mode: VisibilityMode,
    isSilent: boolean,
    nodeVisibilityMap: Map<number, boolean>
    faceVisibilityMap: Map<string, boolean>
    syntheticMeshVisibilityMap: Map<number, boolean>
}

export type VisibilityStateAction =
    { type: VisibilityStateActionType.SET_PART_VISIBILITY, items: Part[], visibility: boolean }
    | { type: VisibilityStateActionType.SET_CHANNEL_VISIBILITY, items: (Part | TreeviewFaceGroup | SyntheticFace)[], visibility: boolean }
    | { type: VisibilityStateActionType.ISOLATE_PART, items: Part[] }
    | { type: VisibilityStateActionType.ISOLATE_CHANNEL, items: (Part | TreeviewFaceGroup | SyntheticFace)[] }
    | { type: VisibilityStateActionType.RESTORE_STATE }
    | { type: VisibilityStateActionType.UPDATE_STATE, nodeVisibilityMap?: Map<number, boolean>, faceVisibilityMap?: Map<string, boolean>, syntheticMeshVisibilityMap?: Map<number, boolean> }
    | { type: VisibilityStateActionType.UPDATE_SYNTHETIC_MESHES, meshes: SyntheticMesh[] }
    | { type: VisibilityStateActionType.SHOW_ALL }
    | { type: VisibilityStateActionType.REINITIALISE }


export type SyntheticMesh = {
    nodeId: number,
    mesh: SyntheticMeshInfo
}

// NodeAccessFn is a function that takes an array of nodes onto which the action
// is directly applied, and returns the array of nodes impacted by the action. For instance, a
// change to the root of a subtree may propagates to all descendant nodes, all related leaf bodies,
// etc.
type NodeAccessFn = (nodeIds: number[]) => number[];


function setPartVisibility(action: { type: VisibilityStateActionType.SET_PART_VISIBILITY } & VisibilityStateAction, state: VisibilityState, nodeAccessFn: NodeAccessFn): VisibilityState {
    const nodeVisibilityMap: Map<number, boolean> = new Map(state.nodeVisibilityMap);

    action.items.map(part => part.nodesIds).flatMap(nodeIds => nodeAccessFn(nodeIds)).forEach(nodeId => {
        nodeVisibilityMap.set(nodeId, action.visibility)
    });

    return {
        ...state,
        nodeVisibilityMap,
        isSilent: false
    }
}

function setChannelVisibility(action: { type: VisibilityStateActionType.SET_CHANNEL_VISIBILITY } & VisibilityStateAction, state: VisibilityState, nodeAccessFn: NodeAccessFn): VisibilityState {
    const nodeVisibilityMap: Map<number, boolean> = new Map(state.nodeVisibilityMap);
    const faceVisibilityMap: Map<string, boolean> = new Map();
    const syntheticMeshVisibilityMap: Map<number, boolean> = new Map();

    action.items.filter(Channel.isPart).map(part => part.nodesIds).flatMap(nodeIds => nodeAccessFn(nodeIds)).forEach(nodeId => {
        nodeVisibilityMap.set(nodeId, action.visibility)
    });

    [...action.items.filter(Channel.isTreeviewFaceGroup).flatMap(i => i.config), ...action.items.filter(Channel.isSyntheticFace)]
        .forEach((face: TreeviewFace | SyntheticFace) => {
            if (Channel.isSyntheticFace(face)) {
                syntheticMeshVisibilityMap.set(face.nodeId, action.visibility);
            } else {
                faceVisibilityMap.set(makeFaceKey(face), action.visibility);
            }
        });



    return {
        ...state,
        nodeVisibilityMap,
        faceVisibilityMap,
        syntheticMeshVisibilityMap,
        isSilent: false
    }
}

function isolateChannel(action: { type: VisibilityStateActionType.ISOLATE_CHANNEL } & VisibilityStateAction, state: VisibilityState, nodeAccessFn: NodeAccessFn): VisibilityState {
    const isolatedState = cloneState(state);

    const nodesAffected: Set<number> = new Set();
    const facesAffected: Set<string> = new Set();
    const syntheticMeshesAffected: Set<number> = new Set();

    action.items.filter(Channel.isPart).map(part => part.nodesIds).flatMap(nodeIds => nodeAccessFn(nodeIds)).forEach(nodeId => nodesAffected.add(nodeId));

    [...action.items.filter(Channel.isTreeviewFaceGroup).flatMap(i => i.config), ...action.items.filter(Channel.isSyntheticFace)]
        .forEach((face: TreeviewFace | SyntheticFace) => {
            if (Channel.isSyntheticFace(face)) {
                syntheticMeshesAffected.add(face.nodeId);
            } else {
                facesAffected.add(makeFaceKey(face));
            }
        });

    for (const [nodeId, _] of isolatedState.nodeVisibilityMap) {
        isolatedState.nodeVisibilityMap.set(nodeId, nodesAffected.has(nodeId));
    }

    for (const [key, _] of isolatedState.faceVisibilityMap) {
        isolatedState.faceVisibilityMap.set(key, facesAffected.has(key));
    }

    for (const [nodeId, _] of isolatedState.syntheticMeshVisibilityMap) {
        isolatedState.syntheticMeshVisibilityMap.set(nodeId, syntheticMeshesAffected.has(nodeId));
    }

    return {
        ...isolatedState,
        mode: VisibilityMode.ISOLATED,
        isSilent: false
    }
}

function isolatePart(action: { type: VisibilityStateActionType.ISOLATE_PART } & VisibilityStateAction, state: VisibilityState, nodeAccessFn: NodeAccessFn) {
    const isolatedState = cloneState(state);
    const nodesAffected: Set<number> = new Set();

    action.items.map(part => part.nodesIds).flatMap(nodeIds => nodeAccessFn(nodeIds)).forEach(nodeId => nodesAffected.add(nodeId));

    for (const [nodeId, _] of isolatedState.nodeVisibilityMap) {
        isolatedState.nodeVisibilityMap.set(nodeId, nodesAffected.has(nodeId));
    }

    return {
        ...isolatedState,
        mode: VisibilityMode.ISOLATED,
        isSilent: false
    }
}

function updateState(action: { type: VisibilityStateActionType.UPDATE_STATE } & VisibilityStateAction, state: VisibilityState): VisibilityState {
    const nodeVisibilityMap = new Map<number, boolean>(state.nodeVisibilityMap);
    const faceVisibilityMap = new Map<string, boolean>(action.faceVisibilityMap ?? state.faceVisibilityMap)
    const syntheticMeshVisibilityMap = new Map<number, boolean>(action.syntheticMeshVisibilityMap ?? state.syntheticMeshVisibilityMap);
    let isSilent: boolean = true;

    for (const [nodeId, visibility] of action.nodeVisibilityMap ?? []) {
        if (isSilent && state.nodeVisibilityMap.has(nodeId) && action.nodeVisibilityMap!.get(nodeId) !== state.nodeVisibilityMap.get(nodeId)) {
            const hasChannelInside = [...state.faceVisibilityMap.keys()].some(key => key.startsWith(`${nodeId}-`));

            isSilent = !hasChannelInside;
        }
        nodeVisibilityMap.set(nodeId, visibility);
    }

    return {
        ...state,
        nodeVisibilityMap,
        faceVisibilityMap,
        syntheticMeshVisibilityMap,
        isSilent
    };
}

function showAll(action: { type: VisibilityStateActionType.SHOW_ALL }, state: VisibilityState): VisibilityState {
    const isolatedState = cloneState(state);

    for (const [nodeId, _] of isolatedState.nodeVisibilityMap) {
        isolatedState.nodeVisibilityMap.set(nodeId, true);
    }

    for (const [key, _] of isolatedState.faceVisibilityMap) {
        isolatedState.faceVisibilityMap.set(key, true);
    }

    return {
        ...isolatedState,
        mode: VisibilityMode.REGULAR,
        isSilent: false
    }
}

function createInitialState(): VisibilityState {
    return {
        mode: VisibilityMode.REGULAR,
        nodeVisibilityMap: new Map(),
        faceVisibilityMap: new Map(),
        syntheticMeshVisibilityMap: new Map(),
        isSilent: false
    }
}

function cloneState(state: VisibilityState): VisibilityState {
    return clone(state, (key, value) => {
        if (key === 'nodeVisibilityMap' || key === 'faceVisibilityMap' || key === 'syntheticMeshVisibilityMap') {
            return [...(value as Map<number | string, boolean>).entries()]
        }
        return value;
    }, (key, value) => {
        if (key === 'nodeVisibilityMap' || key === 'faceVisibilityMap' || key === 'syntheticMeshVisibilityMap') {
            return new Map((value as any[]))
        }
        return value;
    });
}

export function useVisibilityReducer(nodeAccessFn: NodeAccessFn) {
    const [savedState, setSavedState] = useState<VisibilityState | null>(null);

    function reducer(state: VisibilityState, action: VisibilityStateAction): VisibilityState {
        if (action.type === VisibilityStateActionType.REINITIALISE) {
            return createInitialState();
        }

        if (action.type === VisibilityStateActionType.UPDATE_STATE) {
            const updatedState = updateState(action, state);

            setSavedState(prevState => {
                if (prevState) {
                    return {
                        ...prevState,
                        syntheticMeshVisibilityMap: updatedState.syntheticMeshVisibilityMap
                    }
                }
                return prevState;
            });

            return updatedState;
        }

        if (action.type === VisibilityStateActionType.SET_PART_VISIBILITY) {
            return setPartVisibility(action, state, nodeAccessFn);
        }

        if (action.type === VisibilityStateActionType.SET_CHANNEL_VISIBILITY) {
            return setChannelVisibility(action, state, nodeAccessFn);
        }

        if (action.type === VisibilityStateActionType.ISOLATE_PART) {
            setSavedState(state);

            return isolatePart(action, state, nodeAccessFn);
        }

        if (action.type === VisibilityStateActionType.ISOLATE_CHANNEL) {
            setSavedState(state);

            return isolateChannel(action, state, nodeAccessFn);
        }

        if (action.type === VisibilityStateActionType.RESTORE_STATE) {
            const restoredState = savedState ?? state;

            return {
                ...restoredState,
                syntheticMeshVisibilityMap: state.syntheticMeshVisibilityMap,
                isSilent: false
            };
        }

        if (action.type === VisibilityStateActionType.SHOW_ALL) {
            return showAll(action, state);
        }

        return state;
    }

    return useReducer(reducer, createInitialState());
}

