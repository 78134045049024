import { findTreeItemByPath } from "../../../services/TreeviewFunctions";
import { Channel } from "../../../store/job/channel";
import { ChannelEnd, Mold, Part, SyntheticFace, Tree, TreeviewFace, TreeviewFaceGroup } from "../../../store/job/job-data";


export function mapTreeviewFacesToParts(faces: TreeviewFace[], tree: Tree): Part[] {
    return faces.map(face => {
        const path = face.path;
        const treeItem = tree[path];
        return {
            id: path,
            name: treeItem.name,
            path: treeItem.path,
            nodesIds: treeItem.nodeIds,
            cadFileName: treeItem.cadFileName ?? ""
        }
    });
}

function mapTreeViewFaceGroups(groups: TreeviewFaceGroup[], tree: Tree): TreeviewFaceGroup[] {
    const result: TreeviewFaceGroup[] = []; 

    for (const group of groups) {
        const config: (TreeviewFace|SyntheticFace)[] = [];

        for (const face of group.config) {
            const treeItem = findTreeItemByPath(face.path, tree);

            if (!treeItem) {
                continue;
            }

            if (Channel.isSyntheticFace(face)) {
                config.push({
                    ...face,
                    path: treeItem.path,
                    nodeId: 0,
                    faceIndex: 0,
                });
            } else {
                config.push({
                    ...face,
                    nodeId: treeItem.nodeIds[0]
                });
            }
        }

        if (config.length) {
            result.push({
                ...group,
                config
            });
        }
    }

    return result;
}

function mapTerminal(terminals: ChannelEnd[], tree: Tree): ChannelEnd[] {
    return terminals.map(t => {
        const treeItem = findTreeItemByPath(t.path, tree);
        return {
            ...t,
            node: treeItem
        }
    }).filter(t => t.node !== null).map(t => {
        const commonProperties = {
            id: t.id,
            name: t.name,
            type: t.type,
            path: t.node!.path,
            face: {
                faceIndex: t.face.faceIndex,
                nodeId: t.node!.nodeIds[0]
            }
        };

        if(Channel.isSyntheticFace(t.face)){
            return {
                ...commonProperties,
                face: {
                    ...commonProperties.face,
                    config: t.face.config
                }
            }
        }

        return commonProperties;
    });
}


export function mapParts(parts: Part[], tree: Tree): Part[] {
    const result: Part[] = [];

    for (const part of parts) {
        const treeItem = findTreeItemByPath(part.path, tree);

        if (treeItem) {
            result.push({
                id: treeItem.path,
                nodesIds: treeItem.nodeIds,
                name: part.name,
                path: part.path,
                cadFileName: part.cadFileName
            });
        } 
    }

    return result;
}

export function mapPathsToParts(paths: string[], tree: Tree): Part[] {
    return paths.map(path => {
        const treeItem = tree[path];
        return {
            id: path,
            name: treeItem.name,
            path: treeItem.path,
            nodesIds: treeItem.nodeIds,
            cadFileName: treeItem.cadFileName ?? ""
        }
    });
}

export function mapMolds(molds: Mold[], tree: Tree): Mold[] {
    const result: Mold[] = [];

    for (const mold of molds) {
        result.push({
            ...mold,
            bodies: [
                ...mapParts(mold.bodies, tree)
            ]
        });
    }

    return result;
}

export function mapChannels(channels: Channel[], tree: Tree): Channel[] {
    const result: Channel[] = [];

    for (const channel of channels) {
        const mappedChannel = new Channel({
            ...channel,
            inlets: mapTerminal(channel.inlets, tree),
            outlets: mapTerminal(channel.outlets, tree),
            bodies: [
                ...mapParts(channel.getBodyParts(), tree),
                ...mapTreeViewFaceGroups(channel.getBodyFaceGroups(), tree)
            ],
            baffles: [
                ...mapTreeViewFaceGroups(channel.baffles.filter(Channel.isTreeviewFaceGroup), tree),
                ...mapParts(channel.baffles.filter(Channel.isPart), tree)
            ]
        });

        if (!mappedChannel.isEmpty()) {
            result.push(mappedChannel);
        }

    }

    return result;
}

export function getNodeIdsForPart(part: Part): number[] {
    return part.nodesIds;
}

export function getNodeIdsForParts(parts: Part[]): number[] {
    return parts.flatMap(p => p.nodesIds);
}
