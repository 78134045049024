import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import FileManagementService from "../services/FileManagementService";
import { JobChannelsBodiesData } from "../store/job/job-data";
import { channelSelectionModeColorInletOutletDone } from "../store/uiSettings/communicatorColors";
import { objGeometryScalingFactor, parseObjToGeometryData } from "./obj.utils";

export async function generateGeoModelFromChannelBodiesData(
    channelsBodiesData: JobChannelsBodiesData[],
    containerName: string
): Promise<{ result: cee.geo.GeometryModel; errors: Error[] }> {
    const errors: Error[] = [];
    const geoModel = new cee.geo.GeometryModel();
    const partColor = new cee.Color3(
        channelSelectionModeColorInletOutletDone.r / 255,
        channelSelectionModeColorInletOutletDone.g / 255,
        channelSelectionModeColorInletOutletDone.b / 255
    );

    for (const bodiesData of channelsBodiesData) {
        const promises = bodiesData.bodiesDataUrl.map(url => fetchAndProcessGeometryData(url, containerName, geoModel, partColor, errors));

        await Promise.all(promises);
    }

    return { result: geoModel, errors };
}

async function fetchAndProcessGeometryData(
    url: string,
    containerName: string,
    geoModel: cee.geo.GeometryModel,
    partColor: cee.Color3,
    errors: Error[]
): Promise<void> {
    try {
        const rawData = await new FileManagementService().downloadFile(url, containerName);
        const data = parseObjToGeometryData(await rawData.text());
        applyGeometryData(data, url, geoModel, partColor, errors);
    } catch {
        errors.push(new Error(`Data from missing OBJ file "${getFileName(url)}" were ignored.`));
    }
}

function applyGeometryData(
    data: ReturnType<typeof parseObjToGeometryData>,
    url: string,
    geoModel: cee.geo.GeometryModel,
    partColor: cee.Color3,
    errors: Error[]
) {
    data.e.forEach(error => {
        errors.push(new Error(`In file "${getFileName(url)}", ${error.message}`));
    });

    data.m.forEach(meshes => {
        meshes.forEach(face => {
            const part = geoModel.addPart();
            part.mesh = new cee.geo.MeshIndexedTriangles(face.vertices, [0, 1, 2], face.vertexNormals.length ? { vertexNormals: face.vertexNormals } : undefined);
            part.settings.color = partColor;
            part.settings.opacity = 0.5;
            part.settings.visible = false;
            part.transformationMatrix = cee.Mat4.fromScaling({ x: objGeometryScalingFactor, y: objGeometryScalingFactor, z: objGeometryScalingFactor });
        });
    });
}

function getFileName(url: string): string {
    return url.slice(url.lastIndexOf('/') + 1);
}