import { useEffect, useContext, useMemo } from 'react';
import JobContext from '../../store/job/job-context';
import { Tree } from '../../store/job/job-data';
import { SessionNodeId, getCadFileNameProperty } from '../../utils/hoops.utils';
import { TreeviewItemModel } from './TreeviewNodeModel';

interface TreeViewProps {
    Hwv: Communicator.WebViewer,
    reloadTreeStructure: number
}

function createTreeItem(nodeId: SessionNodeId, parentPath: string, cadFileName: string, model: Communicator.Model): TreeviewItemModel | null {
    const nodeName = model.getNodeName(nodeId) ?? "";
    const nodeType = model.getNodeType(nodeId);
    const path = `${parentPath}${nodeName}/`;
    switch (nodeType) {
        case Communicator.NodeType.Part:
        case Communicator.NodeType.PartInstance:
        case Communicator.NodeType.BodyInstance:
        case Communicator.NodeType.AssemblyNode: {
            return {
                nodeIds: [nodeId],
                name: nodeName,
                isSelected: false,
                isVisible: true,
                path: path,
                cadFileName: cadFileName
            };
        }
    };
    return null;
}

function createChildTreeItemsRecursive(treeItems: TreeviewItemModel[], parentNodeId: SessionNodeId, parentPath: string, cadFileName: string, model: Communicator.Model) {
    const childNodeIds = model.getNodeChildren(parentNodeId);
    for (const childNodeId of childNodeIds) {
        const childItem = createTreeItem(childNodeId, parentPath, cadFileName, model);
        if (childItem) {
            treeItems.push(childItem);
            createChildTreeItemsRecursive(treeItems, childNodeId, childItem.path, cadFileName, model);
        }
    }
}

function createTreeItemGroup(treeItems: TreeviewItemModel[]): TreeviewItemModel {
    // Assumes that all other properties are identical.
    const first = treeItems[0]!;
    const groupNodeIds = treeItems.flatMap(i => i.nodeIds);
    const groupName = `${first.name} x ${treeItems.length}`;
    return {
        ...first,
        nodeIds: groupNodeIds,
        name: groupName,
    };
}

function addTreeItemsToTree(treeItems: TreeviewItemModel[], tree: Tree) {
    // Add tree items to the tree. Tree items with identical path must be merged.
    const uniqueItems = new Map<string, TreeviewItemModel[]>();
    for (const item of treeItems) {
        if (!uniqueItems.has(item.path)) {
            uniqueItems.set(item.path, [item]);
        } else {
            uniqueItems.get(item.path)!.push(item);
        }
    }

    uniqueItems.forEach((items, key) => {
        if (items.length > 1) {
            tree[key] = createTreeItemGroup(items);
        } else {
            tree[key] = items[0];
        }
    });
}

async function createTreeFromModel(model: Communicator.Model): Promise<Tree> {
    const tree: Tree = {};

    const topNodeNames = new Set<string>();
    const childNodeIds = model.getNodeChildren(model.getAbsoluteRootNode());

    for (const topNodeId of childNodeIds) {
        var nodeName = model.getNodeName(topNodeId) ?? "";
        if (!nodeName) {
            console.error("Top nodes must have a name.");
            continue;
        }
        if (topNodeNames.has(nodeName)) {
            console.error("Top nodes with identical name are not supported.")
            continue;
        }
        topNodeNames.add(nodeName);
        const cadFileName = await getCadFileNameProperty(topNodeId, model);
        if (!cadFileName) {
            console.error("Top nodes must be associated with a CAD model file.");
            continue;
        }

        // Create a treeItem for every node associated with this cad file.
        const modelTreeItems: TreeviewItemModel[] = []
        const topItem = createTreeItem(topNodeId, '/', cadFileName, model);
        if (topItem) {
            modelTreeItems.push(topItem);
            createChildTreeItemsRecursive(modelTreeItems, topNodeId, topItem.path, cadFileName, model);
        }

        // Add the nodes to the tree.
        addTreeItemsToTree(modelTreeItems, tree);
    }

    return tree;
}

export default function TreeviewStructure(props: TreeViewProps) {
    const jobContext = useContext(JobContext);

    const isTreeLoaded = useMemo(() => jobContext && Object.keys(jobContext.Tree).length > 0 , [jobContext]);

    useEffect(() => {
        async function createTree() {
            if (!props.Hwv) return;
            const tree = await createTreeFromModel(props.Hwv.model);
            jobContext.setTree(tree);
        }
        
        createTree();
    }, [props.Hwv, props.reloadTreeStructure]);

    useEffect(() => {
        jobContext?.setIsTreeLoaded(isTreeLoaded);
    }, [isTreeLoaded])

    return (<></>)
}