import { getNodeIdsForPart } from "../../components/job/helpers/tree-mapper";
import clone from "../../utils/clone";
import { makeFaceKey } from "../../utils/hoops.utils";
import { ChannelEnd, ChannelEndType, Face, Part, SyntheticFace, TreeviewFace, TreeviewFaceGroup } from "./job-data";

export type ChannelProps = { id: string, name: string } & Partial<{
    bodies: (Part | TreeviewFaceGroup)[],
    inlets: ChannelEnd[],
    outlets: ChannelEnd[],
    baffles: (Part | TreeviewFaceGroup)[],
    cadFileName: string | null;
}>

type PartialPart = { id: string } & Partial<Part>;

type PartialFace = { nodeId: number, faceIndex: number } & Partial<TreeviewFace>;

type PartialFaceGroup = { config: PartialFace[] } & Partial<Omit<TreeviewFaceGroup, 'config'>>

export class Channel implements Part {
    readonly id: string = '';

    readonly name: string = '';

    path: string = '';

    nodesIds: number[] = [];

    bodies: (Part | TreeviewFaceGroup)[] = [];

    inlets: ChannelEnd[] = [];

    outlets: ChannelEnd[] = [];

    baffles: (Part | TreeviewFaceGroup)[] = [];

    cadFileName: string | null = null;

    constructor(props: ChannelProps) {
        Object.assign(this, props);

        this.updateInletIdsAndNames();
        this.updateOutletIdsAndNames();
        this.updateBaffleIdsAndNames();
        this.updateFaceGroupsIdsAndNames();
    }

    private updateInletIdsAndNames(): void {
        this.inlets.forEach((i, index) => {
            i.id = `${this.id}-inlet-${index}`;
            i.name = `Inlet ${index + 1}`;
        })
    }

    private updateOutletIdsAndNames(): void {
        this.outlets.forEach((o, index) => {
            o.id = `${this.id}-outlet-${index}`,
                o.name = `Outlet ${index + 1}`;
        });
    }

    private updateBaffleIdsAndNames(): void {
        this.baffles.forEach((b, index) => {
            b.id = `${this.id}-baffle-${index}`;
            b.name = `Baffle ${index + 1}`;
        });
    }

    private updateFaceGroupsIdsAndNames(): void {
        this.getBodyFaceGroups().forEach((faceGroup, index) => {
            faceGroup.id = `${this.id}-body-${index}`;
            faceGroup.name = `Body ${index + 1}`;
        });
    }

    getBodyParts(): Part[] {
        return this.bodies.filter(Channel.isPart);
    }

    getBodyFaceGroups(): TreeviewFaceGroup[] {
        return this.bodies.filter(Channel.isTreeviewFaceGroup);
    }

    getSyntheticFaces(): SyntheticFace[] {
        return [
            ...this.inlets.map(i => i.face).filter(Channel.isSyntheticFace),
            ...this.outlets.map(o => o.face).filter(Channel.isSyntheticFace),
            ...this.baffles.filter(Channel.isTreeviewFaceGroup).flatMap(b => b.config).filter(Channel.isSyntheticFace)
        ]
    }

    hasPart(item: PartialPart): boolean {
        const parts = [...this.bodies, ...this.baffles].filter(Channel.isPart);

        return parts.find(p => p.path === item.path) !== undefined;
    }

    hasFaces(items: TreeviewFace[]): boolean {
        const itemFaceKeys = items.map(makeFaceKey);
        const bodyFaceKeys = this.bodies.filter(Channel.isTreeviewFaceGroup).flatMap(g => g.config).map(makeFaceKey);

        return itemFaceKeys.length > 0 && itemFaceKeys.every(ik => bodyFaceKeys.includes(ik));
    }

    addBody(item: Part | TreeviewFace[]): void {
        if (Channel.isPart(item)) {
            this.bodies.push(item);
        } else {
            const group: TreeviewFaceGroup = {
                id: '',
                name: '',
                config: item
            };

            this.bodies.push(group);
            this.updateFaceGroupsIdsAndNames();
        }
    }

    removeBody(bodyId: string): void {
        const body = this.bodies.find(b => b.id === bodyId)

        if (!body) {
            return;
        }

        this.bodies = this.bodies.filter(b => b !== body);

        if (Channel.isPart(body)) {
            const bodyNodeIds = getNodeIdsForPart(body);

            this.inlets = this.inlets.filter(i => bodyNodeIds.includes(i.face.nodeId) === false);
            this.outlets = this.outlets.filter(o => bodyNodeIds.includes(o.face.nodeId) === false);
            this.baffles = this.baffles.filter(b => {
                if (Channel.isTreeviewFaceGroup(b)) {
                    return b.config.every(face => bodyNodeIds.includes(face.nodeId) === false);
                } else {
                    return getNodeIdsForPart(b).every(nodeId => bodyNodeIds.includes(nodeId) === false);
                }
            });
        }

        if (Channel.isTreeviewFaceGroup(body)) {
            const bodyFaceKeys = body.config.map(face => makeFaceKey(face));

            this.inlets = this.inlets.filter(i => bodyFaceKeys.includes(makeFaceKey(i.face)) === false);
            this.outlets = this.outlets.filter(o => bodyFaceKeys.includes(makeFaceKey(o.face)) === false);
            this.baffles = this.baffles.filter(b => {
                if (Channel.isTreeviewFaceGroup(b)) {
                    return b.config.every(face => bodyFaceKeys.includes(makeFaceKey(face)) === false);
                } else {
                    return true;
                }
            });
        }

        this.updateInletIdsAndNames();
        this.updateOutletIdsAndNames();
        this.updateBaffleIdsAndNames();
        this.updateFaceGroupsIdsAndNames();
    }

    addChannelEnd(item: TreeviewFace | SyntheticFace, type: ChannelEndType) {
        const terminal = this.getTerminalByFaceType(type, item);
        const filterPredicate = this.comparer(terminal.face);

        if (type === ChannelEndType.Inlet) {
            this.inlets = [terminal];
            this.outlets = this.outlets.filter(filterPredicate);
        } else {
            this.inlets = this.inlets.filter(filterPredicate);
            this.outlets.push(terminal);
        }

        this.updateInletIdsAndNames();
        this.updateOutletIdsAndNames();
    }

    getVisibleItems(): (Part | TreeviewFaceGroup | SyntheticFace)[] {
        return [
            ...this.bodies,
            ...this.inlets.filter(i => Channel.isSyntheticFace(i.face)).map(i => i.face) as SyntheticFace[],
            ...this.outlets.filter(o => Channel.isSyntheticFace(o.face)).map(o => o.face) as SyntheticFace[],
            ...this.baffles.filter(Channel.isTreeviewFaceGroup).flatMap(b => b.config).filter(f => Channel.isSyntheticFace(f)) as SyntheticFace[],
            ...this.baffles.filter(Channel.isPart) as Part[]
        ]
    }

    private comparer(face: Face | SyntheticFace) {
        //TODO. there is some very similar code on channelconfigurationcomponent, refactor
        if (Channel.isSyntheticFace(face)) {
            const equals = (a: number[], b: number[]) => a.length === b.length && a.every((value, index) => value === b[index]);

            return (end: ChannelEnd): boolean => {
                if (Channel.isSyntheticFace(end.face)) {
                    return !equals(end.face.config.center, face.config.center);
                }
                return true;
            }
        }

        return (end: ChannelEnd): boolean => end.face.nodeId !== face.nodeId || end.face.faceIndex !== face.faceIndex;
    }

    private getTerminalByFaceType(type: ChannelEndType, item: TreeviewFace | SyntheticFace): ChannelEnd {

        const commonProperties: ChannelEnd = {
            id: '',
            name: '',
            type: type,
            path: item.path,
            face: {
                nodeId: item.nodeId,
                faceIndex: item.faceIndex
            }
        }

        if (Channel.isSyntheticFace(item)) {
            return {
                ...commonProperties,
                face: {
                    ...commonProperties.face,
                    config: item.config
                }
            };
        }

        return commonProperties;
    }

    removeChannelEnd(terminalId: string, type: ChannelEndType) {
        if (type === ChannelEndType.Inlet) {
            this.inlets = this.inlets.filter(i => i.id !== terminalId);
        } else {
            this.outlets = this.outlets.filter(o => o.id !== terminalId);
        }

        this.updateInletIdsAndNames();
        this.updateOutletIdsAndNames();
    }

    addBaffle(items: (TreeviewFace[] | SyntheticFace[] | Part)) {
        if (Channel.isPart(items)) {
            this.baffles.push(items);
        } else {
            this.baffles.push({
                id: '',
                name: '',
                config: items
            });
        }


        this.updateBaffleIdsAndNames();
    }

    removeBaffle(baffleId: string) {
        this.baffles = this.baffles.filter(b => b.id !== baffleId);

        this.updateBaffleIdsAndNames();
    }

    getAllFaces(): Face[] {
        let faces = this.getBodyFaceGroups().flatMap(fg => fg.config).map(f => ({
            nodeId: f.nodeId,
            faceIndex: f.faceIndex
        }));
        let baffleFaces = this.baffles.filter(Channel.isTreeviewFaceGroup).flatMap(b => b.config).map(f => ({
            nodeId: f.nodeId,
            faceIndex: f.faceIndex
        }))
        faces = [...faces, ...this.inlets.map(i => i.face), ...this.outlets.map(o => o.face), ...baffleFaces];

        return faces;
    }

    clone(): Channel {
        const channelProps = clone<ChannelProps>(this);

        return new Channel(channelProps);
    }

    isBodyChannel(): boolean {
        return this.getBodyFaceGroups().length === 0;
    }

    isEmpty(): boolean {
        return this.bodies.length === 0 && this.baffles.length === 0 && this.inlets.length === 0 && this.outlets.length === 0;
    }

    
    hasGenericName(): boolean {
        const regex = new RegExp(`^${Channel.CHANNEL_NAME_PATTERN}\\s\\d+$`);
        return regex.test(this.name);
    }

    static isPart = (item: Part | any): item is Part => (item as Part).nodesIds !== undefined && (item as Part).nodesIds !== null;

    static isTreeviewFaceGroup = (item: TreeviewFaceGroup | any): item is TreeviewFaceGroup => (item as TreeviewFaceGroup).config !== undefined
        && Array.isArray((item as TreeviewFaceGroup).config);

    static isSyntheticFace = (item: SyntheticFace | any): item is SyntheticFace => (item as SyntheticFace).config !== undefined
        && (item as SyntheticFace).config?.center !== undefined && (item as SyntheticFace).config.vertexes !== undefined;

    static jsonReviver(key: string, value: any): any {
        if (key === 'Channel') {
            return (value as ChannelProps[]).map(p => new Channel(p))
        }
        if (key === 'SelectedChannel') {
            return new Channel(value as ChannelProps)
        }
        if (key === 'face') {
            if (value.config) {
                return {
                    nodeId: value.nodeId || value.bodyNodeId,
                    faceIndex: value.faceIndex,
                    config: value?.config
                }
            }

            return {
                nodeId: value.nodeId || value.bodyNodeId,
                faceIndex: value.faceIndex
            }
        }

        return value;
    }

    private static CHANNEL_ID_PATTERN: string = 'channel-';

    private static CHANNEL_NAME_PATTERN: string = 'Channel';

    static createChannelId(index: number): string {
        return `${Channel.CHANNEL_ID_PATTERN}${index}`;
    }

    static createChannelName(index: number): string {
        return `${Channel.CHANNEL_NAME_PATTERN} ${index + 1}`;
    }
}