import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import { useContext } from "react";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import JobContext from "../../store/job/job-context";
import theme from "../../styles/main-theme";
import { Channel } from "../../store/job/channel";
import { useTranslation } from 'react-i18next';
import { HoopsEntitiesContext } from "../../store/job/hoops-entities-context";
import { getNodeIdsForPart } from "../job/helpers/tree-mapper";

export interface ChannelBafflesProps {
    channel: Channel,
    Hwv: Communicator.WebViewer,
    onEnterEditMode: (channel: Channel, baffleId: string, source: 'baffles' | 'bodies') => Promise<void>,
    onEnterRepairMode: (channel: Channel, baffleId: string) => Promise<void>,
    disableControl: boolean,
    disableHighlighting: boolean
}

export function ChannelBaffles(props: ChannelBafflesProps) {
    const jobContext = useContext(JobContext);
    const { unregisterMeshes } = useContext(HoopsEntitiesContext);
    const { t } = useTranslation();
    const channelHasFaceGroups = props.channel.getBodyFaceGroups().length > 0;

    function highlightBaffle(channelId: string, baffleId: string) {
        if (props.disableHighlighting) {
            return;
        }

        const channel = jobContext.Categories.Channel.find(c => c.id === channelId);
        if (!channel) {
            return;
        }
        const baffle = channel.baffles.find(b => b.id === baffleId);

        if (baffle) {
            props.Hwv.model.resetModelHighlight();

            if (Channel.isTreeviewFaceGroup(baffle)) {
                baffle.config.forEach(dg => {
                    if (!Channel.isSyntheticFace(dg)) {
                        props.Hwv.model.setNodeFaceHighlighted(dg.nodeId, dg.faceIndex, true);
                    }
                });
            } else {
                props.Hwv.model.setNodesHighlighted(getNodeIdsForPart(baffle), true);
            }

        }
    }

    function removeBaffle(channelId: string, baffleId: string) {
        const channel = jobContext.Categories.Channel.find(c => c.id === channelId);
        const baffle = channel?.baffles.find(b => b.id === baffleId);

        if (channel && baffle) {
            Channel.isTreeviewFaceGroup(baffle) && unregisterMeshes(baffle.config.filter(Channel.isSyntheticFace).map(f => f.config));
            jobContext.removeChannelBaffle(channelId, baffleId);
        }

    }

    return (<List sx={{ padding: 0, width: "100%" }}>
        {(props.channel.baffles || []).map(b => (
            <ListItem id={"channel-baffle-list-item-" + b.id} key={b.id} sx={{ padding: "0 0 0 15px", '& .MuiListItemSecondaryAction-root': { right: 0 } }} secondaryAction={
                <>
                    {true && channelHasFaceGroups === true && <IconButton disabled={props.disableControl || props.disableHighlighting} onClick={() => props.onEnterRepairMode(props.channel, b.id)} sx={{ padding: 0, color: theme.palette.common.black }}>
                        <BuildRoundedIcon titleAccess={t("Repair baffle")} />
                    </IconButton>}
                    {Channel.isPart(b) == false && <IconButton disabled={props.disableControl || props.disableHighlighting} onClick={() => props.onEnterEditMode(props.channel, b.id, 'baffles')} sx={{ padding: 0, color: theme.palette.common.black }}>
                        <EditRoundedIcon titleAccess={t("Edit baffle")} />
                    </IconButton>}
                    <IconButton disabled={props.disableControl || props.disableHighlighting} onClick={() => removeBaffle(props.channel.id, b.id)} sx={{ right: 0, padding: 0, color: theme.palette.common.black }}>
                        <RemoveCircleTwoToneIcon titleAccess={t("Remove baffle")} />
                    </IconButton>

                </>

            } onMouseEnter={() => highlightBaffle(props.channel.id, b.id)} onMouseLeave={() => highlightBaffle(props.channel.id, b.id)}>
                <ListItemText
                    primary={b.name}
                    primaryTypographyProps={{
                        variant: 'caption',
                    }}
                />
            </ListItem>))}
    </List>)
}