import { createContext, Dispatch, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { TreeviewItemModel } from "../../components/job/TreeviewNodeModel";
import { getAllDescendantNodeIds, makeFaceKey, parseFaceKey, selectNodeIds, SessionNodeId } from "../../utils/hoops.utils";
import { Channel } from "./channel";
import { Part, Tree, TreeviewFaceGroup } from "./job-data";
import { useUpdateOnChange } from "../../hooks/useUpdateOnChange";
import JobContext from "./job-context";
import { SyntheticMesh, useVisibilityReducer, VisibilityMode, VisibilityStateAction, VisibilityStateActionType } from "./hoops-visibility-reducer";

export type HoopsVisiblityContextType = {
    mode: VisibilityMode,
    updateVisibility: Dispatch<VisibilityStateAction>,
    getVisibility: (item: Part | TreeviewFaceGroup) => boolean,
    setSelectedNodes: (nodeIds: SessionNodeId[]) => void,
    reset: () => void,
    onReset: (cb: ResetCallback) => Function
}

export const HoopsVisibilityContext = createContext<HoopsVisiblityContextType>({
    updateVisibility: () => null,
    getVisibility: () => true,
    setSelectedNodes: () => null,
    reset: () => null,
    onReset: (_: ResetCallback) => () => null,
    mode: VisibilityMode.REGULAR
})

type FaceVisibilityRecord = {
    visible: number[],
    hidden: number[]
}

type ResetCallback = () => void;

export function useVisibilityContext(syntheticMeshes: SyntheticMesh[], hwv: Communicator.WebViewer | null) {
    const jobContext = useContext(JobContext);
    const [changesToNodeVisibility, setNodeVisibilityMap] = useState<Map<number, boolean>>(new Map());
    const [changesToFaceVisibility, setFaceVisibilityMap] = useState<Map<number, FaceVisibilityRecord>>(new Map());
    const meshes = useUpdateOnChange(syntheticMeshes);
    const getRelatedSubtreeNodeIds = useCallback((nodeIds: number[]) => hwv !== null ? getAllDescendantNodeIds(nodeIds, hwv.model, true) : [], [hwv]);
    const treeNodeItemNodeIdMap = useMemo(() => {
        const map = new Map<number, TreeviewItemModel>();

        if (jobContext.IsTreeLoaded) {
            const items = Object.values(jobContext.Tree);

            for (const item of items) {
                item.nodeIds.forEach(nodeId => map.set(nodeId, item));
            }
        }
        return map;
    }, [jobContext.IsTreeLoaded]);
    const resetCallbacks = useRef<Set<ResetCallback>>(new Set());

    const [state, dispatch] = useVisibilityReducer(getRelatedSubtreeNodeIds);

    const getVisibility = useCallback((item: Part | TreeviewFaceGroup): boolean => {
        if (Channel.isTreeviewFaceGroup(item)) {
            return item.config.every(face => state.faceVisibilityMap.get(makeFaceKey(face)) === true);
        } else {
            return item.nodesIds.every(nodeId => {
                return state.nodeVisibilityMap.get(nodeId) === true;
            });
        }
    }, [state]);

    const setSelectedNodes = useCallback((nodeIds: SessionNodeId[]): void => {
        // TODO On principle, automatic selection in the callback selectArray
        //      should be temporarily disabled here. The selection should be
        //      taken as specified. In practice, smart selection should converge
        //      to the same result, so it should not matter.
        if (hwv && nodeIds.length > 0) {
            selectNodeIds(nodeIds, hwv);
        }
    }, [state]);

    const updateStateFromHoops = useCallback((showBodyIds: SessionNodeId[], hideBodyIds: SessionNodeId[]) => {
        // Synchronize internal state after a visibility change originating from HOOPS.
        //
        // The callback is triggered when nodes are shown or hidden from HOOPS
        // (via the model tree or context menu for instance), outside of the
        // boundary of our react app. The two arrays contain the ids of the
        // bodies that changed, and only the bodies. If both arrays are empty,
        // only the visibility of structural nodes (nodes that do not directly
        // contain geometry) changed.
        //
        // Notes on HOOPS visibility state:
        // --------------------------------
        // - Making a node visible (from the UI) changes all descendant nodes to visible.
        // - Making a node invisible (from the UI) changes all descendant nodes to invisible.
        // - Changing child visibility does not change the visibility of the parent.
        // - A branch can have mixed visibility:
        //   a) It is possible for the parent to be invisible, and the child to be visible.
        //      It that case, the child will be shown, even if the parent is not.
        //   b) It is also possible for all the children to be invisible, but for the parent
        //      to remain visible. In that case, the parent will be "shown", but since
        //      a parent node must be a component by definition (non-geometric node),
        //      there will be nothing to display.
        //   These mixed cases can be inspected with getBranchVisibility() instead of
        //   getNodeVisibility() but we don't care too much about it.
        // - Visibility of faces and edges must also be considered, especially
        //   in the case of channels. TO BE DOCUMENTED.
        // - Synthetic nodes. TO BE DOCUMENTED.
        if (hwv === null) {
            return;
        }

        const model = hwv.model;
        const nodeVisibilityMap = new Map<SessionNodeId, boolean>();

        // Process the incremental changes.
        for (const nodeId of showBodyIds) {
            nodeVisibilityMap.set(nodeId, true);
        }
        for (const nodeId of hideBodyIds) {
            nodeVisibilityMap.set(nodeId, false);
        }

        // Do a full sync. We care about the visibility of components too.
        // Query the model to get the state of the other nodes.
        for (const nodeId of treeNodeItemNodeIdMap.keys()) {
            if (nodeVisibilityMap.has(nodeId) === false) {
                nodeVisibilityMap.set(nodeId, model.getNodeVisibility(nodeId));
            }
        }

        dispatch({
            type: VisibilityStateActionType.UPDATE_STATE,
            nodeVisibilityMap: nodeVisibilityMap
        });
    }, [getRelatedSubtreeNodeIds, treeNodeItemNodeIdMap]);

    const reset = () => {
        resetCallbacks.current.forEach(cb => cb());
    };

    const onReset = (cb: ResetCallback) => {
        resetCallbacks.current.add(cb);

        return () => {
            resetCallbacks.current.delete(cb);
        }
    }


    useEffect(() => {
        dispatch({
            type: VisibilityStateActionType.UPDATE_STATE,
            syntheticMeshVisibilityMap: new Map<number, boolean>(meshes.map(m => [m.nodeId, true]))
        })
    }, [meshes]);

    useEffect(() => {
        if (state.isSilent) {
            return;
        }

        const changesToNodeVisibility: Map<number, boolean> = new Map();
        const changesToFaceVisibility: Map<number, {
            visible: number[],
            hidden: number[]
        }> = new Map();


        for (const [nodeId, visibility] of state.nodeVisibilityMap.entries()) {
            changesToNodeVisibility.set(nodeId, visibility);
        }

        for (const [nodeId, visibility] of state.syntheticMeshVisibilityMap.entries()) {
            changesToNodeVisibility.set(nodeId, visibility);
        }


        setNodeVisibilityMap(changesToNodeVisibility);

        let needsNodeVisibilityUpdate = false;

        for (const [key, visibility] of state.faceVisibilityMap.entries()) {
            const face = parseFaceKey(key);
            const nodeVisibility = state.nodeVisibilityMap.get(face.nodeId);
            const record = changesToFaceVisibility.get(face.nodeId) || {
                visible: [],
                hidden: []
            };

            changesToFaceVisibility.set(face.nodeId, record);

            if (nodeVisibility === visibility) {
                continue;
            }

            if (visibility === false) {
                record.hidden.push(face.faceIndex);
            } else {
                record.visible.push(face.faceIndex);
            }

            changesToNodeVisibility.set(face.nodeId, true);
            needsNodeVisibilityUpdate = true;
        }

        needsNodeVisibilityUpdate && setNodeVisibilityMap(changesToNodeVisibility);

        setFaceVisibilityMap(changesToFaceVisibility);
    }, [state]);

    useEffect(() => {
        async function exec(changesToNodeVisibility: Map<number, boolean>, hwv: Communicator.WebViewer) {
            hwv.unsetCallbacks({
                visibilityChanged: updateStateFromHoops
            });

            await hwv.model.setNodesVisibilities(changesToNodeVisibility);

            for (const [nodeId, _] of changesToNodeVisibility) {
                const nodeType = hwv.model.getNodeType(nodeId);

                if (nodeType === Communicator.NodeType.BodyInstance) {
                    try {
                        hwv.model.clearNodeFaceVisibility(nodeId);
                        hwv.model.clearNodeLineVisibility(nodeId);
                    } catch (e) {
                        console.error(nodeId, e)
                    }
                }

            }

            hwv.setCallbacks({
                visibilityChanged: updateStateFromHoops
            });

        }
        if (hwv && changesToNodeVisibility.size) {
            exec(changesToNodeVisibility, hwv);
        }
    }, [hwv, changesToNodeVisibility, updateStateFromHoops]);

    useEffect(() => {
        async function exec(map: Map<number, FaceVisibilityRecord>, hwv: Communicator.WebViewer) {
            for (const [nodeId, record] of map.entries()) {
                const nodeType = hwv.model.getNodeType(nodeId);

                if (nodeType === Communicator.NodeType.BodyInstance) {
                    hwv.model.clearNodeFaceVisibility(nodeId);
                    hwv.model.clearNodeLineVisibility(nodeId);
    
                    if (record.hidden.length || record.visible.length) {
                        record.hidden.forEach(h => hwv.model.setNodeFaceVisibility(nodeId, h, false));
    
                        if (record.visible.length) {
                            const faceCount = await hwv.model.getFaceCount(nodeId);
    
                            for (let faceIndex = 0; faceIndex < faceCount; faceIndex++) {
                                hwv.model.setNodeFaceVisibility(nodeId, faceIndex, record.visible.includes(faceIndex));
                            }
                        }
    
                        const lineCount = await hwv.model.getEdgeCount(nodeId);
    
                        for (let lineIndex = 0; lineIndex < lineCount; lineIndex++) {
                            hwv.model.setNodeLineVisibility(nodeId, lineIndex, false);
                        }
                    }
                }
            }
        }

        if (hwv) {
            exec(changesToFaceVisibility, hwv);
        }
    }, [hwv, changesToFaceVisibility]);

    useEffect(() => {
        if (treeNodeItemNodeIdMap.size) {
            dispatch({
                type: VisibilityStateActionType.UPDATE_STATE,
                nodeVisibilityMap: new Map<number, boolean>([...treeNodeItemNodeIdMap.keys()].map(nodeId => [nodeId, true]))
            });
        }
    }, [treeNodeItemNodeIdMap]);

    useEffect(() => {
        hwv?.setCallbacks({
            visibilityChanged: updateStateFromHoops
        });

        return () => {
            hwv?.unsetCallbacks({
                visibilityChanged: updateStateFromHoops
            });
        }
    }, [hwv, updateStateFromHoops]);

    useEffect(() => {
        if (treeNodeItemNodeIdMap.size && changesToNodeVisibility.size > 0) {
            const subTree: Tree = {};

            for (const [nodeId, visibility] of changesToNodeVisibility) {
                const treeItem = treeNodeItemNodeIdMap.get(nodeId);

                if (treeItem) {
                    subTree[treeItem.path] = {
                        ...treeItem,
                        isVisible: visibility
                    }
                }
            };

            const newTree = {
                ...jobContext.Tree,
                ...subTree
            };

            jobContext.setTree(newTree);
        }
    }, [changesToNodeVisibility, treeNodeItemNodeIdMap]);


    return {
        state,
        getVisibility,
        updateVisibility: dispatch,
        setSelectedNodes,
        reset,
        onReset
    }
}